.Login {
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 30vw;
  margin: auto;

  form {
    display: flex;
    flex-direction: column;

    * {
      margin: 0.2vh 0;
    }

    input, input:focus {
      border: 1px solid #f3f3f4;
      color: #f3f3f4;
      background-color: #1A1A1D;
    }

    ::placeholder {
      color: #4e4e50;
    }
  }
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}
