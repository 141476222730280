.Sidebar li {
  border-radius: 25px;
}

.Sidebar li :hover {
  background-color: #232327;
}

.Sidebar {
  margin-right: 25px;

  div {
    background-color: #6F2232;
  }

  .head-div {
    padding: 24px 33px !important;

    .icon-suffix {
      margin: 0 !important;
    }
  }

  .pro-menu {
    ul {
      button {
        padding: auto;
        width: 100%;
        background-color: inherit !important;
        text-align: left;
        height: 65px;

        li {
          div {
            padding: 0 0 0 7px;
            border-radius: 10px !important;
          }
        }
      }
    }
  }
}