* {
    font-family: "Roboto";
    font-weight: bold;
    color: #f3f3f4;
    text-decoration: none !important;
}

.App {
    height: 100vh;
    background-color: #1A1A1D;
}

button, button:focus, button:hover, button:active{
  background-color: #c3073f !important;
  border: none;
  outline: none;
}

button:disabled {
  background-color: #6f2232;
}

.btn {
  border: none !important;
  box-shadow: none !important;
}