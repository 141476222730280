.Service {
  display: flex;
  background-color: #232327;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-right: 25px;
  width: calc(100% - 25px) !important;
  line-height: 36px;

  * {
    margin: auto 0;
  }


  button {
    width: 40px !important;
    border-radius: 0.25rem;
    margin: 0 25px 0 auto;

    svg {
      margin: 0;
    }
  }

  svg {
    margin-right: 20px;
  }
}